'use client';
import Slider from '@/components/molecules/Slider';
import ProductCard from '@/components/organisms/Products';
import useAuth from '@/contexts/AuthProvider';
import { API_PUBLIC } from '@/services/apis/apiConstrant';
import AroggaAPI from '@/services/apis/AroggaAPI';
import { checkIsValidColor } from '@/services/utils/helper';
import generateBlockLink from '@/services/utils/LinkGenerator';
import cls from 'classnames';
import { find, get } from 'lodash';
import Link from 'next/link';
import { useCallback, useEffect, useState } from 'react';
interface Props {
	data?: any;
	key?: any;
	sl?: any;
}

const PreviouslyBrowsedItems = ({ data, sl }: Props) => {
	const [products, setProducts] = useState([]);
	const { isLogged } = useAuth();
	const { block_machine_name, block, block_color_code, block_weight, extra } = data;
	const loadData = useCallback(async () => {
		const variantObject = find(extra, function (o) {
			return '{_variant_id}' in o;
		});
		const variantId = get(variantObject, '{_variant_id}', null);
		const { data } = await AroggaAPI.get(API_PUBLIC.GET_BLOCK, {
			_page: 1,
			_block: block_machine_name,
			_allow_sales: 1,
			...(variantId && { _exclude_product_variant_id: variantId })
		});
		const blockdata = get(data, '0', {});
		setProducts(blockdata.block_data || []);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [block_machine_name]); // add any dependencies here
	useEffect(() => {
		if (isLogged) {
			loadData();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLogged, loadData]);

	const { link } = generateBlockLink(data);

	if (!isLogged) {
		return null;
	}
	if (products.length === 0) {
		return null;
	}
	return (
		<div
			className={cls('py-30 ')}
			style={{
				backgroundColor: checkIsValidColor(block_color_code) ? `${block_color_code}` : 'transparent'
			}}>
			<div
				className='container product-slider'
				style={{
					position: 'relative',
					zIndex: 100 - parseInt(sl)
				}}>
				<div className='d-flex justify-space-between items-center mb-30'>
					<h2 className='text-30 text-grey900 fw-600 mb-0'>{block}</h2>
					{products.length > 5.6 && (
						<Link href={link || ''}>
							<div
								className='text-20 text-grey900 fw-700 cursor-pointer hover-text-primary'
								style={{
									color: checkIsValidColor(block_color_code) ? block_color_code : ''
								}}>
								see all
							</div>
						</Link>
					)}
				</div>

				<div className='my-10  mt-10 items-center'>
					<Slider
						slidesPerView={6}
						spaceBetween={20}
						shouldFetchData={block_machine_name}
						SlideComponent={ProductCard}
						slidesPerGroup={6}
						responsive={{
							'0': {
								slidesPerView: 2
							},
							'640': {
								slidesPerView: 2
							},
							'768': {
								slidesPerView: 3
							},
							'1024': {
								slidesPerView: 3,
								spaceBetween: 10
							},
							'1280': {
								slidesPerView: 4,
								spaceBetween: 10
							},
							'1440': {
								slidesPerView: 6
							}
						}}>
						{products.map((product, index) => (
							<div key={index} style={{ width: 'inherit' }}>
								<ProductCard data={product} />
							</div>
						))}
					</Slider>
				</div>
			</div>
		</div>
	);
};

export default PreviouslyBrowsedItems;
